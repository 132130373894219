import React from 'react'

const SvgDownload = props => (
	<svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M8.8484 12.098C8.8664 12.1211 8.88941 12.1398 8.91568 12.1526C8.94195 12.1655 8.97078 12.1721 9 12.1721C9.02922 12.1721 9.05805 12.1655 9.08432 12.1526C9.11059 12.1398 9.1336 12.1211 9.1516 12.098L11.8468 8.67628C11.9455 8.55071 11.8564 8.36477 11.6952 8.36477H9.91203V0.193182C9.91203 0.0869318 9.8254 0 9.71952 0H8.27567C8.16979 0 8.08315 0.0869318 8.08315 0.193182V8.36236H6.30481C6.14358 8.36236 6.05455 8.5483 6.15321 8.67386L8.8484 12.098ZM17.8075 11.2528H16.3636C16.2578 11.2528 16.1711 11.3398 16.1711 11.446V15.1648H1.82888V11.446C1.82888 11.3398 1.74225 11.2528 1.63636 11.2528H0.192513C0.086631 11.2528 0 11.3398 0 11.446V16.2273C0 16.6547 0.344118 17 0.770053 17H17.2299C17.6559 17 18 16.6547 18 16.2273V11.446C18 11.3398 17.9134 11.2528 17.8075 11.2528Z'
			fill='white'
		/>
	</svg>
)

export default SvgDownload
