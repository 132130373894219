import React from 'react'

const SvgArrowRight = props => (
	<svg 
		width="50" 
		height="48" 
		viewBox="0 0 50 48" 
		fill="none" 
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path 
			d="M0.28125 24C0.28125 10.7452 11.0264 0 24.2812 0L25.7812 0C39.0361 0 49.7812 10.7452 49.7812 24C49.7812 37.2548 39.0361 48 25.7812 48H24.2812C11.0264 48 0.28125 37.2548 0.28125 24Z" 
			fill={props.fill}
		/>
		<g clip-path="url(#clip0_735_14705)">
			<path 
				d="M22.9689 30.6669C22.788 30.6679 22.6086 30.6343 22.441 30.5679C22.2735 30.5016 22.1211 30.4038 21.9927 30.2802C21.8638 30.1563 21.7615 30.0088 21.6917 29.8463C21.6219 29.6839 21.5859 29.5096 21.5859 29.3336C21.5859 29.1575 21.6219 28.9833 21.6917 28.8208C21.7615 28.6583 21.8638 28.5108 21.9927 28.3869L26.5439 24.0002L22.1714 19.5869C21.9153 19.3371 21.7716 18.9991 21.7716 18.6469C21.7716 18.2947 21.9153 17.9567 22.1714 17.7069C22.2992 17.5819 22.4513 17.4827 22.6189 17.415C22.7864 17.3474 22.9661 17.3125 23.1477 17.3125C23.3292 17.3125 23.5089 17.3474 23.6765 17.415C23.844 17.4827 23.9961 17.5819 24.1239 17.7069L29.4314 23.0402C29.6833 23.2895 29.8245 23.6246 29.8245 23.9736C29.8245 24.3226 29.6833 24.6577 29.4314 24.9069L23.9314 30.2402C23.808 30.3695 23.6594 30.4737 23.4942 30.547C23.329 30.6202 23.1505 30.661 22.9689 30.6669Z" 
				fill="white" 
			/>
		</g>
		<defs>
			<clipPath id="clip0_735_14705">
				<rect width="33" height="32" fill="white" transform="translate(8.53125 8)" />
			</clipPath>
		</defs>
	</svg>
)

export default SvgArrowRight
