import styled, { css } from 'styled-components'

import { Container } from '@material-ui/core'


export const PaddingContainer = styled.div`
	${({  }) => css`		
		padding: 16px;
	`}
`

export const InstructionsContainer = styled.div`
	${({ theme }) => css`
		background: #ffffff;
		box-shadow: 0px 0px 8px 3px rgba(117, 117, 117, 0.15);
		border-radius: 24px;
		margin-bottom: 24px;

		p {
			font-family: ${theme.typography.primary} !important;
			color: #302A38 !important;
		}	

	`}
`

export const InfoContainer = styled.div`
	${({ theme }) => css`
		background: #ffffff;
		//box-shadow: 4px 5px 8px -3px rgba(117, 117, 117, 0.15);
		box-shadow: 0px 0px 8px 3px rgba(117, 117, 117, 0.15);
		border-radius: 24px;
		border: 1px solid rgba(117, 117, 117, 0.1);
		padding: 16px;

		p {
			font-family: ${theme.typography.primary} !important;
		}		

		a {
			display: flex;
			align-items: center;		
			text-decoration: none !important;
			color: ${theme.color.primary};
			cursor: pointer;

			svg {
				margin-top: 1px !important;
			}
		}		
	`}
`

export const InfoUnitContainer = styled.div`
	${({  }) => css`
		background: #ffffff;
		box-shadow: 4px 5px 8px -3px rgba(117, 117, 117, 0.15);
		border-radius: 24px;
		padding: 16px;
		background-color: #ffffff !important;
	`}
`

export const NeedSchedule = styled.div`
	${({ theme }) => css`
		font-family: ${theme.typography.primary};
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 40px;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 16px;
		margin-top: 16px;

		background: #1E9E94;

		&.need {
			background: #f26529;
		}
	`}
`

export const ExamsContainer = styled(Container)`
	${({  }) => css`
		padding-left: 16px;
	`}
`

export const Spacing = styled.section`
	${({  }) => css`
		margin-top: 24px;
	`}
`

export const SpacingMin = styled.section`
	${({  }) => css`
		margin-top: 12px;
	`}
`
