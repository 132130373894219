import React from 'react'

const SvgArrowLeft = props => (
	<svg 
		width="51" 
		height="48" 
		viewBox="0 0 51 48" 
		fill="none" 
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path 
			d="M0.53125 24C0.53125 10.7452 11.2764 0 24.5312 0L26.0312 0C39.2861 0 50.0312 10.7452 50.0312 24C50.0312 37.2548 39.2861 48 26.0312 48H24.5312C11.2764 48 0.53125 37.2548 0.53125 24Z" 
			fill={props.fill}
		/>
		<g clip-path="url(#clip0_735_14706)">
			<path d="M27.1515 30.6664C26.9663 30.6657 26.7831 30.6288 26.613 30.5577C26.4429 30.4867 26.2893 30.3831 26.1615 30.2531L20.854 24.9198C20.6021 24.6705 20.4609 24.3354 20.4609 23.9864C20.4609 23.6374 20.6021 23.3023 20.854 23.0531L26.354 17.7198C26.4822 17.5954 26.6344 17.4968 26.8019 17.4295C26.9694 17.3623 27.1489 17.3276 27.3302 17.3276C27.5115 17.3276 27.6911 17.3623 27.8586 17.4295C28.0261 17.4968 28.1783 17.5954 28.3065 17.7198C28.4347 17.8441 28.5364 17.9917 28.6058 18.1541C28.6752 18.3165 28.7109 18.4906 28.7109 18.6664C28.7109 18.8422 28.6752 19.0163 28.6058 19.1788C28.5364 19.3412 28.4347 19.4888 28.3065 19.6131L23.769 23.9998L28.1415 28.3998C28.3976 28.6496 28.5413 28.9875 28.5413 29.3398C28.5413 29.692 28.3976 30.0299 28.1415 30.2798C28.0114 30.4049 27.8568 30.5036 27.6868 30.57C27.5167 30.6364 27.3348 30.6692 27.1515 30.6664Z" 
			fill="white" 
		/>
		</g>
		<defs>
			<clipPath id="clip0_735_14706">
				<rect width="33" height="32" fill="white" transform="translate(8.78125 8)" />
			</clipPath>
		</defs>
	</svg>

)

export default SvgArrowLeft
