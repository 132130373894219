const fleury = {
  marca: 'fleury',
  idMarca: '1'
}
const weinmann = {
  marca: 'weinmann',
  idMarca: '15'
}
const amais = {
  marca: 'felippe-mattoso',
  idMarca: '20'
}

const getBrand = () => {
  const brand = process.env.GATSBY_COSMIC_BUCKET.toLowerCase()

  switch (brand) {
    case 'fleury':
      return fleury
    case 'weinmann':
      return weinmann
    case 'felippe-mattoso':
      return amais
    default:
      return fleury
  }
}

export default getBrand
