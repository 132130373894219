export const findUrlAndConvertToLink = (text: string): string => {
    const urlRegex = /(\b(https?:\/\/|www\.)[^\s/$.?#].[^\s]*)/gi;
    return text.replace(urlRegex, (url) => {
        let link = url;
        if (!url.match(/^(https?:\/\/)/)) {
            link = `http://${url}`;
        }
        return `<a href="${link}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
};
