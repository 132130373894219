import req from '../utils/base-axios'
import getBrand from '../utils/getBrand'

const urlBff = '/bff-acesso/websites/v1'

const getInstructionsByExams = async examsId => {
	const response = await req
		.get(`${urlBff}/instrucoes-gerais/pdf?idMarca=${getBrand().idMarca}&idExame=${examsId}`, { responseType: 'blob' })
		.then(response => response.data)
		.catch(error => error.response.data)

	return response
}

export { getInstructionsByExams }
